import React, { FC } from "react";
import {
  getPaginationNodes,
  getPaginationData,
  getPaginationInitialData,
} from "@helpers/getPaginationNodes";
import { PostOrderBy, PostsQuery, PostsQueryVariables } from "@graphql/types";
import { useInfiniteQuery } from "react-query";
import { graphql } from "@helpers/graphql";
import { t } from "@helpers/translate";
import PostsBlock from "@components/common/PostsBlock";
import { Spinner } from "@components/ui/Spinner";
import LoadMore from "@components/common/Pagination/LoadMore";

interface PostsProps {
  initialData?: PostsQuery;
  countryName?: string;
  filters?: PostsQueryVariables;
  showUser?: boolean;
  postTitle?: string;
  showLoadMore?: boolean;
  columnsCount?: number;
}

export const Posts: FC<PostsProps> = ({
  initialData,
  countryName,
  filters,
  showUser = false,
  postTitle,
  showLoadMore = true,
  columnsCount,
}) => {
  const { isLoading, data, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery(
      ["supplier-posts", filters],
      async ({ pageParam }) => {
        const res = await graphql.posts({
          ...filters,
          after: pageParam,
        });

        return getPaginationData(res.posts);
      },
      {
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        initialData: getPaginationInitialData(initialData?.posts),
      }
    );

  if (filters?.filters?.ORDER_BY === PostOrderBy.RankingDesc) {
    const title =
      postTitle !== undefined
        ? postTitle
        : countryName !== undefined
          ? t({
              id: "blogs.popular.country.articles",
              message: `Popular ${countryName} Articles`,
              values: {
                countryName: `${countryName}`,
              },
            })
          : t({
              id: "blogs.popular.articles",
              message: "Popular Articles",
            });

    return (
      <PostsBlock
        orientation="vertical"
        title={title}
        columns="4"
        showUser={showUser}
        data={getPaginationNodes(data)}
        className="mb-8"
      />
    );
  }

  if (filters?.filters?.ORDER_BY === PostOrderBy.CreatedAtDesc) {
    const title =
      postTitle !== undefined
        ? postTitle
        : countryName !== undefined
          ? t({
              id: "blogs.latest.country.articles",
              message: `Latest ${countryName} Articles`,
              values: {
                countryName: `${countryName}`,
              },
            })
          : t({
              id: "blogs.latest.articles",
              message: "Latest Articles",
            });

    return (
      <PostsBlock
        orientation="vertical"
        title={title}
        columns="4"
        showUser={showUser}
        data={getPaginationNodes(data)}
        className="mb-8"
      />
    );
  }

  return (
    <PostsBlock
      orientation="vertical"
      title={
        postTitle ||
        t({
          id: "supplier.block.posts.title-new",
          message: "Posts By This Supplier",
        })
      }
      columns={columnsCount === 4 ? "4" : "2"}
      showUser={showUser}
      data={getPaginationNodes(data)}
      className="mb-8"
    >
      {isLoading && <Spinner />}

      {!isLoading && data && showLoadMore && (
        <LoadMore
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          text={t({ id: "pagination.button.load-more", message: "Load More" })}
        />
      )}
    </PostsBlock>
  );
};
